<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-6 c g">
      <div class="card">
        <div class="card-header">
          <h4 class="text-center">
            <i class="fa fa-user-plus"></i>
            {{ e("add-user") }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <h5 for="">{{ e("name") }}</h5>
            <input
              type="text"
              class="form-control form-control-lg"
              v-model="admin.name"
              :placeholder="e('write-here')"
            />
          </div>
          <div class="form-group">
            <h5 for="">{{ e("username") }}</h5>
            <input
              type="text"
              class="form-control form-control-lg"
              v-model="admin.username"
              :placeholder="e('write-here')"
            />
          </div>
          <div class="form-group">
            <h5 for="">{{ e("password") }}</h5>
            <input
              type="text"
              class="form-control form-control-lg"
              v-model="admin.password"
              :placeholder="e('write-here')"
            />
          </div>
          <!-- switch -->
          <div class="demo-inline-spacing">
            <b-form-checkbox
              class="custom-control-success"
              name="check-button"
              v-model="admin.all_rules"
              :value="true"
              switch
              inline
            >
              <strong> {{ e("allow-all-rules") }}</strong>
            </b-form-checkbox>
          </div>
          <div v-if="!admin.all_rules" class="border g">
            <div
              class="demo-inline-spacing"
              v-for="rule in rules"
              :key="rule.name"
            >
              <b-form-checkbox
                class="custom-control-primary"
                name="check-button"
                v-model="admin.rules"
                :value="rule.route"
                switch
                inline
              >
                <strong> {{ e(rule.name) }}</strong>
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-12 text-center g">
            <button class="btn btn-relief-success btn-lg" @click="addNow()">
              {{ e("edit") }}
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { BLink, BOverlay, BFormCheckbox } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BOverlay,
    BFormCheckbox,
  },
  data() {
    return {
      loading: true,
      user: JSON.parse(localStorage.getItem("user")),
      rules: [],
      admin: {
        rules: [],
      },
    };
  },
  created() {
    var g = this;
    $.post(api + "/general/rules", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.rules = JSON.parse(r);
        $.post(api + "/admins/admin", {
          jwt: g.user.jwt,
          id: window.location.pathname.split("edit/")[1],
        })
          .then(function (r) {
            g.admin = JSON.parse(r);
            g.loading = false;
          })
          .catch(function (e) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "Error: " + e,
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      })
      .catch(function (e) {
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "Error: " + e,
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    e(d) {
      return e(d);
    },
    addNow() {
      var _g = this;
      _g.loading = true;
      $.post(api + "/admins/edit", {
        jwt: _g.user.jwt,
        admin: this.admin,
      })
        .then(function (response) {
          response = JSON.parse(response);
          if (response.status == 100) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e("user-added"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
          } else {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e(response.response),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          }
          _g.loading = false;
        })
        .catch(function () {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: e("error"),
              icon: "TimesIcon",
              variant: "danger",
            },
          });
          _g.loading = false;
        });
    },
  },
};
</script>